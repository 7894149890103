import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Logo from '@components/Logo'
import useSiteMetadata from '@helpers/useSiteMetadata'

export const HeaderLogo = ({ ...props }) => {
  const { title } = useSiteMetadata()

  const { logo } = useStaticQuery(logoQuery)
  const { fixed } = logo && logo.childImageSharp

  return fixed ? <Logo fixed={fixed} title={title} {...props} /> : null
}

const logoQuery = graphql`
  query LogoQuery {
    logo: file(absolutePath: { regex: "/logo.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fixed(width: 200, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
